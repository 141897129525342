import React from 'react'

import { FavouritesPage } from '../components/FavouritesPage'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'

const Favourites = ({ data }) => {
  console.log(data)

  const post = data.page

  return (
    <Layout generic>
      <SEO
        title={post.seo.title}
        image={post.avContent.featureImage?.sourceUrl}
        description={post.seo.metaDesc}
      />

      <FavouritesPage title={post.title} customData={data.page.avFavList} />
    </Layout>
  )
}

export default Favourites

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      seo {
        title
        metaDesc
      }
      avContent {
        featureImage {
          sourceUrl
          srcSet
        }
      }
      avFavList {
        flIntroText
        flItems {
          ... on WpOperator {
            id
            title
            databaseId
            uri
            excerpt
            avOperatorDetails {
              text
            }
            avContent {
              featureImage {
                sourceUrl
                srcSet
              }
            }
            avContactInfo {
              venue
              address
              bookingUrl
              phone
              postcode
              state
              suburb
              website
              days {
                times
                day
              }
            }
          }
          ... on WpEvent {
            id
            title
            databaseId
            uri
            excerpt
            avOperatorDetails {
              text
            }
            avContent {
              featureImage {
                sourceUrl
                srcSet
              }
            }
            avEvents {
              dates {
                date
              }
              showTime
            }
            avContactInfo {
              venue
              address
              bookingUrl
              website
              suburb
              state
              postcode
              phone
            }
          }
        }
      }
    }
  }
`
